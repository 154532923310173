import { HttpLink } from 'apollo-angular/http';
import { OAuthService } from 'angular-oauth2-oidc';
import { setContext } from '@apollo/client/link/context';

import { onError } from '@apollo/client/link/error';
import { createClient } from 'graphql-ws';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition, Observable } from '@apollo/client/utilities';
import { ApolloLink, split, InMemoryCache, makeVar, Operation, NextLink } from '@apollo/client/core';

import { ExecutionResult } from 'graphql';

import { RetryLink } from '@apollo/client/link/retry';
import { schema } from '@tx/api';

const isObject = (value: any) => {
  return !!(value && typeof value === 'object' && !Array.isArray(value));
};

const traverseObject: CallableFunction = (object: any, input: any, types: any) => {
  if (isObject(object)) {
    for (const key in object) {
      const objectKey = key;
      const objectValue = object[key];

      const arg = input?.inputFields.find((i: any) => i.name === objectKey);
      if (arg) {
        //console.log(`found ${objectKey}`);
      } else {
        //console.log(`not found ${objectKey}`);
        //console.log(`delete ${objectKey} from `, object);
        //@ts-ignore
        delete object[objectKey];
        continue;
      }

      if (isObject(objectValue)) {
        // console.log(arg.type);
        traverseObject(
          objectValue,
          types.find((t: any) => t.name === arg.type.ofType.name),
          types
        );
      }
      if (Array.isArray(objectValue)) {
        for (const iterator of objectValue) {
          traverseObject(
            iterator,
            types.find((t: any) => t.name === arg.type.ofType.ofType.name),
            types
          );
        }
      }
    }
  }

  return null;
};

const typenameMiddleware = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    const types = schema.__schema.types.map((s: any) => s);
    const mutation = types.find((t: any) => t.name === 'Mutation');
    const operationType = mutation.fields.find((m: any) => m.name === operation.operationName);
    if (operationType) {
      try {
        for (const key in operation.variables) {
          if (Object.prototype.hasOwnProperty.call(operation.variables, key)) {
            const arg = operationType.args.find((a: any) => a.name === key);
            if (arg) {
              traverseObject(
                operation.variables['input'],
                types.find((t: any) => t.name === arg.type?.ofType?.name),
                types
              );
              //console.log(operation.variables);
            } else {
              //console.log('arg fehlt');
            }
          }
        }
      } catch (e) {
        // console.log(e);
      }
    }
  }
  return forward(operation);
});

const introspectionLink = new ApolloLink((operation, forward) => {
  switch (operation.operationName.toLowerCase()) {
    case 'introspectionquery':
      return new Observable<ExecutionResult>((subscriber) => {
        subscriber.next({
          data: schema
        });
        subscriber.complete();
      });
  }

  if (forward) {
    return forward(operation);
  }

  throw new Error(`Unable to handle operation ${operation.operationName}`);
});

export function createApollo(
  httpLink: HttpLink,
  oauthService: OAuthService | undefined,
  notificationService: any,
  environment: any
) {
  const uri = environment.graphqlEndpoint;
  const basic = setContext((operation, context) => ({
    headers: {
      Accept: '*/*, application/json'
    }
  }));

  const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      for (let err of graphQLErrors) {
        switch (err.extensions?.['code']) {
          // Apollo Server sets code to UNAUTHENTICATED
          // when an AuthenticationError is thrown in a resolver
          case 'UNAUTHENTICATED':
            // Modify the operation context with a new token
            const oldHeaders = operation.getContext()['headers'];
            if (!oauthService) {
              //@ts-ignore
              return;
            }
            const token = oauthService.getAccessToken();
            if (!token) {
              return forward(operation);
            }

            operation.setContext({
              headers: {
                ...oldHeaders,
                authorization: `Bearer ${token}`
              }
            });

            // Retry the request, returning the new observable
            return forward(operation);
        }
      }

      if (window.location.hostname !== 'my.telemaxx.de') {
        graphQLErrors.forEach((error) => {
          if (error.message.includes('does not exist')) {
            notificationService.showError('[DEV] Keine Daten!', `Ist die APP geseeded?`, 3600 * 100);
          }
          notificationService.showError('[GRAPHQL] Error:', `${error.message}`, 3600 * 100);
        });
      } else {
        notificationService.showError('API Error', 'Das ging schief');
      }
    }

    // To retry on network errors, we recommend the RetryLink
    // instead of the onError link. This just logs the error.
    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
    }
    //@ts-ignore
    return;
  });

  const auth = setContext((operation, context) => {
    if (!oauthService) {
      return {};
    }
    const token = oauthService.getAccessToken();

    if (token === null) {
      return {};
    } else {
      return {
        headers: {
          authorization: `Bearer ${token}`
        }
      };
    }
  });
  const ws = new GraphQLWsLink(
    createClient({
      url: environment.graphqlEndpointWs,
      connectionParams: {
        //authToken: oauthService.getAccessToken()
      }
    })
  );

  const retryLink = new RetryLink({
    delay: {
      initial: 300,
      max: 100,
      jitter: true
    },
    attempts: {
      max: 10
    }
  });
  const link = split(
    // split based on operation type
    ({ query }) => {
      const definition = getMainDefinition(query);
      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
    },
    ws,
    ApolloLink.from([
      introspectionLink,
      basic,
      auth,
      typenameMiddleware,
      errorLink,
      httpLink.create({ uri }),
      retryLink
    ])
  );

  const cache = new InMemoryCache();

  return {
    link,
    cache,
    assumeImmutableResults: true,
    connectToDevTools: true
  };
}
