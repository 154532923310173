import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NonNullableFormBuilder } from '@angular/forms';
import { Icon } from '@n/nui';

export interface Option {
  type: string;
  value: string;
  label: string;
}

export interface Suggestion {
  icon: Icon;
  value: string;
  label: string;
}

@Component({
  selector: 'tx-portal-magic-search',
  templateUrl: './magic-search.component.html'
})
export class MagicSearchComponent implements OnInit {
  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent) {
    if (!this.ref.nativeElement.contains(event.target)) {
      this.isSuggestionListOpen = false;
    }
  }

  @ViewChild('input') input!: ElementRef;
  @ViewChild('ref') ref!: ElementRef;

  constructor(private fb: NonNullableFormBuilder) {}

  ngOnInit(): void {
    this.filterSuggestions();
    this.searchForm.controls.input.valueChanges.subscribe((value) => {
      this.backspacePressed = 0;
      if (value !== '') {
        this.suggestionFocused = 0;
      } else {
        this.suggestionFocused = -1;
      }
      this.filterSuggestions();
    });
  }

  @Output() onSearch: EventEmitter<Option[]> = new EventEmitter<Option[]>();

  searchForm = this.fb.group({ input: '' });

  @Input() searchSuggestions: Suggestion[] = [{ icon: 'magnifying-glass', value: 'text', label: 'Full Text Search' }];
  filteredSuggestions: Suggestion[] = [...this.searchSuggestions];
  isSuggestionListOpen = false;
  suggestionFocused = -1;

  currentSuggestion: Suggestion | undefined;

  options: Option[] = [];

  // backspace functions
  backspacePressed = 0;
  checkBackspace() {
    if (this.searchForm.controls.input.value === '') {
      this.backspacePressed += 1;
      if (this.backspacePressed > 1) {
        if (this.currentSuggestion) {
          this.unselectSuggestion();
          this.backspacePressed = 0;
        } else {
          this.deleteOption(this.options[this.options.length - 1]);
          this.backspacePressed = 0;
        }
      }
    }
  }

  checkEnter() {
    if (
      this.searchForm.controls.input.value === '' &&
      this.currentSuggestion === undefined &&
      this.options.length > 0 &&
      this.suggestionFocused === -1
    ) {
      return this.search();
    }
    if (this.currentSuggestion === undefined && this.suggestionFocused > -1 && this.filteredSuggestions.length > 0) {
      this.selectSuggestion(this.filteredSuggestions[this.suggestionFocused]);
      this.searchForm.controls.input.setValue('');
      return;
    }
    if (this.currentSuggestion && this.searchForm.controls.input.value !== '') {
      return this.finishOption({
        type: this.currentSuggestion.value,
        value: this.searchForm.controls.input.value,
        label: this.currentSuggestion.label
      });
    }
    return this.finishOption({
      type: 'text',
      value: this.searchForm.controls.input.value,
      label: 'Full Text Search'
    });
  }

  filterSuggestions() {
    // reset value
    this.filteredSuggestions = [...this.searchSuggestions];

    // remove suggestions if already in options
    this.filteredSuggestions = [...this.filteredSuggestions].filter((s) => {
      return !this.options.some((o) => {
        return o.type === s.value;
      });
    });

    // search for input
    if (this.searchForm.controls.input.value !== '') {
      this.filteredSuggestions = [...this.filteredSuggestions].filter((s) => {
        return s.label.toLowerCase().includes(this.searchForm.controls.input.value.toLowerCase());
      });
    }
    // für Fulltext brauch ich den extra schritt eigentlich nicht?
    // this.filteredSuggestions.push({ icon: 'magnifying-glass', value: 'text', label: 'Full Text Search' });
  }

  // focus up or down functions
  focusUpSuggestion() {
    if (this.filteredSuggestions.length < 1) return;
    if (this.suggestionFocused === 0 || this.suggestionFocused === -1) {
      this.suggestionFocused = this.filteredSuggestions.length - 1;
    } else {
      this.suggestionFocused = this.suggestionFocused - 1;
    }
  }
  focusDownSuggestion() {
    if (this.filteredSuggestions.length < 1) return;
    if (this.suggestionFocused === this.filteredSuggestions.length - 1) {
      this.suggestionFocused = 0;
    } else {
      this.suggestionFocused = this.suggestionFocused + 1;
    }
  }

  // suggestion functions
  selectSuggestion(suggestion: Suggestion) {
    this.currentSuggestion = suggestion;
    this.input.nativeElement.focus();
    this.suggestionFocused = -1;
  }
  unselectSuggestion() {
    if (!this.currentSuggestion) return;
    this.searchForm.controls.input.setValue(this.currentSuggestion.label);
    this.currentSuggestion = undefined;
  }

  // options functions
  finishOption(option: Option) {
    this.options.push(option);
    this.searchForm.controls.input.setValue('');
    this.currentSuggestion = undefined;
    this.search();
  }
  deleteOption(option: Option) {
    this.options = this.options.filter((o) => {
      return o !== option;
    });
    this.input.nativeElement.focus();
    this.filterSuggestions();
    this.search();
  }

  // search functions
  search() {
    this.isSuggestionListOpen = false;
    this.input.nativeElement.blur();
    this.onSearch.emit(this.options);
  }
}
